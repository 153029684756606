<template>
  <div class="quotes">
    <h5Top />
    <div class="h5header">
      <img src="../../public/images/new/backicon.png" alt="" class="return" @click="$router.go(-1)" />
      <div class="name flexcenter">{{ $t("new.desc5") }}</div>
      <div class="lang">
        <img src="../../public/images/new/recordicon.png" alt="" @click="$router.push('/quotes/record')" />
      </div>
    </div>
    <div class="quotes-con">
      <div class="quotes-title">
        {{ $t("quotes.desc1") }}
        <p class="flex" @click="$router.push('/quotes/record')">
          {{ $t("quotes.desc12") }}
          <img src="../../public/images/land/righticon1.png" alt="" />
        </p>
      </div>
      <div class="quotes-wrappar flex">
        <div class="slider-left">
          <div class="left-top flex">
            <div>{{ $t("quotes.desc2") }}</div>
            <div>{{ $t("quotes.desc3") }}(DU)</div>
            <div>{{ $t("quotes.desc4") }}</div>
            <div>{{ $t("quotes.desc5") }}</div>
          </div>
          <div class="left-list">
            <div class="left-info flex" v-for="item in coinList" :key="item.id">
              <div class="info-icon flex">
                <img :src="item.icon" alt="" />
                {{ item.symbol }}
              </div>
              <div class="info-icon">{{ common.cutXiaoNum1(item.usdtPrice) }}</div>
              <div class="info-icon" :class="{
                color1: parseFloat(item.oneDayUpdown) > 0,
                color2: parseFloat(item.oneDayUpdown) < 0,
              }">
                {{ item.oneDayUpdown }}%
              </div>
              <div class="info-icon" :class="{
                color1: parseFloat(item.threeDayUpdown) > 0,
                color2: parseFloat(item.threeDayUpdown) < 0,
              }">
                {{ item.threeDayUpdown }}%
              </div>
            </div>
          </div>
        </div>
        <div class="slider-right">
          <div class="h5title flex">
            <div class="title-name">{{ $t("quotes.desc32") }}</div>
            <div class="item-info-more" :class="{ actimg: ischeck }" @click="handlerMore(index)">
              <span v-if="!ischeck">{{ $t("quotes.desc33") }}</span>
              <span v-else>{{ $t("planet.desc65") }}</span>
              <img src="../../public/images/new/downicon3.png" alt="" />
            </div>
          </div>
          <!-- <div class="right-line" v-if="ischeck">
            <div class="line-bg"></div>
            <div id="myChart" v-if="showEcharts"></div>
          </div> -->
          <div class="right-con">
            <div class="right-name">
              {{ $t("quotes.desc8", { n1: configInfo.payCoinName,n2: configInfo.returnCoinName}) }}
            </div>
            <div class="right-money flex">
              <div>1 {{ configInfo.payCoinName }} ≈ {{ common.cutXiaoNum1(configInfo.payCoinPrice/configInfo.returnCoinPrice) }} {{ configInfo.returnCoinName }}</div>
              <Circle v-model:current-rate="currentRate" :rate="10" :speed="100" text="" size="18" :stroke-width="180" start-position="left" layer-color="#464646" color="#C9FA5B" />
              <el-tooltip effect="dark" placement="bottom">
                <template #content>
                  <div v-html="$t('quotes.desc34')"></div>
                </template>
                <img src="../../public/images/land/tag1.png" alt="" />
              </el-tooltip>
            </div>
            <div class="right-exchange flex">
              <div class="right-select">
                <div class="select-info flex">
                  <input type="text" :placeholder="$t('quotes.desc9')" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, '$1')" v-model="amount" @change="onChange" />
                  <el-dropdown>
                    <span class="el-dropdown-link">
                      <img :src="configInfo.payCoinIcon == undefined ? require('../../public/images/logoimg.png') : configInfo.payCoinIcon" alt="" class="img1" />
                      {{ configInfo.payCoinName }}
                      <img src="../../public/images/new/downicon.png" alt="" class="img2" />
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item v-for="item,index in paycoinList" @click.native="selectInfo(item)" :key="index">
                          <div class="select-icon flex">
                            <img :src="item.payCoinIcon" alt="" />
                            {{ item.payCoinName }}
                          </div>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
                <!-- 余额 -->
                <div class="select-price">
                  {{ $t("quotes.desc10") }} {{ oneCoinInfo.balance }}{{ configInfo.payCoinName }}
                </div>
              </div>
              <!-- :class="{ grey: configInfo.isTwoWay == 2 }" -->
              <img src="../../public/images/new/exchangeicon.png" alt="" class="icon" style="cursor: not-allowed;"/>
              <div class="right-select">
                <div class="select-info flex">
                  <input type="text" :placeholder="$t('quotes.desc9')" oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, '$1')" v-model="returnAmount" @change="onChange1" />
                  <el-dropdown>
                    <span class="el-dropdown-link">
                      <img :src="configInfo.returnCoinIcon == undefined ? require('../../public/images/logoimg.png') : configInfo.returnCoinIcon" alt="" class="img1" />
                      {{ configInfo.returnCoinName }}
                      <img src="../../public/images/new/downicon.png" alt="" class="img2" />
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item v-for="item,index in paycoinList" @click.native="selectInfo(item)" :key="index">
                          <div class="select-icon flex">
                            <img :src="item.returnCoinIcon" alt="" class="img1"/>
                            {{ item.returnCoinName }}
                          </div>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
                <!-- 余额 -->
                <div class="select-price">
                  {{ $t("quotes.desc10") }} {{ twoCoinInfo.balance }}{{ configInfo.returnCoinName }}
                </div>
              </div>
            </div>
            <div class="right-btn1" @click="handlerShow">
              {{ $t("quotes.desc37") }} 
            </div>
            <!-- <div class="right-btn1" @click="handlerShow" v-else>
              {{ $t("quotes.desc35") }} {{ configInfo.returnCoinName }}
            </div> -->
          </div>
        </div>
      </div>
      <div class="quotes-record">
        <div class="record-name">{{ $t("quotes.desc12") }}</div>
        <div class="record-tbody flex">
          <div>{{ $t("quotes.desc13") }}</div>
          <div>{{ $t("quotes.desc14") }}</div>
          <div>{{ $t("quotes.desc15") }}</div>
          <!-- <div>{{ $t("quotes.desc16") }}</div> -->
          <div>{{ $t("quotes.desc17") }}</div>
          <div>{{ $t("quotes.desc25") }}</div>
          <div>{{ $t("quotes.desc19") }}</div>
          <div>{{ $t("quotes.desc27") }}</div>
          <div>{{ $t("quotes.desc18") }}</div>
        </div>
        <div class="record-list">
          <van-list v-model:loading="loading1" :finished="finished" :loading-text="$t('quotes.desc20')" @load="onLoad">
            <van-cell v-for="item in list" :key="item.id">
              <div class="list-con">
                <div class="list-info flex">
                  <div>{{ $t("quotes.desc13") }}</div>
                  <p class="flex">
                    {{ dealAddress(item.orderNo) }}
                    <img src="../../public/images/new/copy.png" alt="" @click="copy($event, item.orderNo)" />
                  </p>
                </div>
                <div class="list-info flex">
                  <div>{{ $t("quotes.desc14") }}</div>
                  <p>{{ item.datetime }}</p>
                </div>
                <div class="list-info flex">
                  <div>{{ $t("quotes.desc15") }}</div>
                  <p>{{ common.cutXiaoNum1(item.payCoinName) }}/{{ item.returnCoinName }}</p>
                </div>
                <!-- <div class="list-info flex">
                  <div>{{ $t("quotes.desc16") }}</div>
                  <p style="color:#C9FA5B" v-if="item.sellType == 2">{{ $t("quotes.desc35") }}</p>
                  <p v-else>{{ $t("quotes.desc24") }}</p>
                </div> -->
                <div class="list-info flex">
                  <div>{{ $t("quotes.desc17") }}</div>
                  <p>{{ common.cutXiaoNum1(item.payAmount) }} {{ item.amountCoinId }}</p>
                </div>
                <div class="list-info flex">
                  <div>{{ $t("quotes.desc25") }}</div>
                  <p>{{ common.cutXiaoNum1(item.rate) }} {{ item.toDisPatchReturnName }}</p>
                </div>
                <div class="list-info flex">
                  <div>{{ $t("quotes.desc19") }}</div>
                  <p>{{ common.cutXiaoNum1(item.fee) }} {{ item.feeCoinName }}</p>
                </div>
                <div class="list-info flex">
                  <div>{{ $t("quotes.desc27") }}</div>
                  <p>{{ common.cutXiaoNum1(item.returnAmount) }} {{ item.toDisPatchReturnName }}</p>
                </div>
                <div class="list-info flex">
                  <div>{{ $t("quotes.desc18") }}</div>
                  <p>{{ $t("market.desc77") }}</p>
                </div>
              </div>
            </van-cell>
          </van-list>
        </div>
        <div class="no-data" v-if="showNull">
          <img src="../../public/images/no-data.png" alt="" />
          <span>{{ $t("record.desc13") }}</span>
        </div>
        <div class="planet-page animate__animated animate__fadeInUp animate__delay-1s">
          <el-pagination layout="prev, pager, next" :pager-count="5" :page-size="10" :current-page="page" :total="totals"
            :hide-on-single-page="true" @current-change="handlerCurrent">
          </el-pagination>
        </div>
      </div>
    </div>
    <!--  -->
    <el-dialog v-model="showTips" :append-to-body="true">
      <div class="modal-con">
        <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showTips = false" />
        <div class="modal-title">{{ $t("quotes.desc23") }}</div>
        <div class="modal-list">
          <div class="list-info flex">
            <div>{{ $t("quotes.desc38") }}</div>
            <p>{{ configInfo.returnCoinName }}</p>
          </div>
          <div class="list-info flex">
            <div>{{ $t("quotes.desc25") }}</div>
            <p>
              1 {{ configInfo.payCoinName }} ≈ {{ common.cutXiaoNum1(configInfo.payCoinPrice/configInfo.returnCoinPrice) }} {{ configInfo.returnCoinName }}
            </p>
          </div>
          <div class="list-info flex">
            <div>{{ $t("quotes.desc26") }}</div>
            <p>{{ amount }} {{ configInfo.payCoinName }}</p>
          </div>
          <div class="list-info flex">
            <div>{{ $t("quotes.desc19") }}</div>
            <p>{{ free }} {{ configInfo.feeCoinName }}</p>
          </div>
          <div class="list-info flex">
            <div>{{ $t("quotes.desc27") }}</div>
            <p>{{ conAmonut }} {{ configInfo.returnCoinName }}</p>
          </div>
        </div>
        <div class="modal-btn flexcenter" @click="handlerSowPsd">
          {{ $t("common.desc3") }}
        </div>
        <div class="modal-tips1">{{ $t('quotes.desc34') }}</div>
      </div>
    </el-dialog>
    <!-- 资金密码 -->
    <el-dialog v-model="showpsd" :append-to-body="true">
      <div class="modal-con">
        <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showpsd = false" />
        <div class="modal-title">{{ $t("home.desc7") }}</div>
        <div class="modal-desc">{{ $t("node.desc53") }}</div>
        <div class="modal-input">
          <psdinput @finish="finish" @finish1="finish1" ref="PsdInput" v-if="showpsd" />
        </div>
        <div class="modal-btncon flexcenter" @click="handlerSubmit" v-loading="loading">
          {{ $t("home.desc20") }}
        </div>
        <div class="forgottitle" @click="handlerLogpsd">{{ $t('sign.desc72') }}</div>
      </div>
    </el-dialog>
    <div id="footer">
      <glFooter :act="3" />
    </div>
  </div>
</template>

<script>
import psdinput from "@/componets/psdinput.vue";
import Clipboard from "@/utils/libs/clipboard";
import glFooter from "@/componets/footer.vue";
import h5Top from "@/componets/h5top.vue";
import { Circle } from 'vant';
import bus from "@/utils/bus";
export default {
  components: {
    psdinput,
    glFooter,
    h5Top,
    Circle
  },
  data() {
    return {
      currentRate: 0,
      ischeck: true,
      showpsd: false,
      myChart: null,
      list: [],
      loading1: false,
      finished: false,
      coinList: [],
      coinInfo: {},
      paycoinList: [],
      configInfo: {},
      oneCoinInfo: {},
      twoCoinInfo: {},
      amount: "",
      returnAmount: "",
      showTips: false,
      loading: false,
      pasword: "",
      userInfo: {},
      page: 1,
      totals: 0,
      showEcharts: true,
      showNull: false,
      timerNull: null,
      free: 1,
      conAmonut: 0,
      isMore: false,
      timerNull1: null,
      timeSep: 10,
      drawData: [],
      coinRate: 0,
      payCoinId: 0,
      returnCoinId: 0
    };
  },
  mounted() {
    let _this = this;
    _this.getInfo()
    if (window.screen.width < 768) {
      _this.ischeck = false;
    }
    if (localStorage.getItem("token") != null) {
      _this.$post(_this.URL.member.info, {}).then((res) => {
        if (res.code == 0) {
          _this.userInfo = res.data;
        }
      });
    }
    // _this.timerNull = setInterval(() => {
    //   _this.getConfig();
    //   _this.$post(_this.URL.quotes.list, {}).then((res) => {
    //     if (res.code == 0) {
    //       _this.coinList = res.data;
    //     } else {
    //       _this.$message.error(res.message);
    //     }
    //   });
    // }, 10000);
    _this.timerNull1 = setInterval(() => {
      _this.timeSep--;
      if (_this.timeSep == 0) {
        _this.currentRate = 0;
        _this.timeSep = 10;
      } else {
        _this.currentRate += 10
      }

    }, 1000)
  },
  beforeUnmount() {
    clearInterval(this.timerNull)
    clearInterval(this.timerNull1)
  },
  methods: {
    handlerLogpsd() {
      this.showpsd = false;
      if (localStorage.getItem('ismobile') == 1) {
        this.$router.push('/user/info?id=2')
      } else {
        bus.emit("hadleropen", 1);
      }
    },
    getInfo() {
      this.$post(this.URL.quotes.list, {}).then((res) => {
        if (res.code == 0) {
          this.coinList = res.data;
          // this.getline(this.coinList[0].id);
          this.getConfigList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handlerMore(i) {
      this.ischeck ? (this.ischeck = false) : (this.ischeck = true);
      if (this.ischeck) {
        setTimeout(() => {
          // this.getline(this.coinList[0].id);
        }, 100);
      }
    },
    handlerCurrent(val) {
      this.page = val;
      this.loading = false;
      this.onLoad();
    },
    copy(e, text) {
      let that = this;
      Clipboard(e, text).then((res) => {
        that.$message.success(this.$t("planet.desc61"));
      });
    },
    dealAddress(str) {
      if (str && str.length > 12) {
        return (
          str.substring(0, 6) +
          "...." +
          str.substring(str.length - 6, str.length)
        );
      } else {
        return str;
      }
    },
    finish(val) {
      this.pasword = val;
    },
    finish1(val) {
      if (val == 0) {
        this.pasword = "";
      }
    },
    handlerSowPsd() {
      this.showpsd = true;
      this.showTips = false;
    },
    //兑换交易提交
    handlerSubmit() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$post(this.URL.quotes.exchange, {
        configId: this.configInfo.configId,
        amount: this.amount,
        payCoinId: this.configInfo.payCoinId,
        returnCoinId: this.configInfo.returnCoinId,
        password:
          this.userInfo.sourceType == 2 || this.userInfo.sourceType == 3
            ? this.pasword
            : this.common.mdpassword(this.pasword),
      }).then((res) => {

        if (res.code == 0) {
          this.$message.success(this.$t("quotes.desc29"));
          this.showpsd = false;
          this.amount = "";
          this.returnAmount = "";
          this.getMoney(1, this.configInfo.payCoinId);
          this.getMoney(2, this.configInfo.returnCoinId);
          this.page = 1;
          this.finished = false;
          this.loading = false;
          this.onLoad();
        } else {
          this.loading = false;
          this.pasword = "";
          this.$refs.PsdInput.reset();
          this.$message.error(res.message);
        }
      });
    },
    handlerShow() {
      if (this.amount == "") {
        this.$message.error(this.$t("quotes.desc28"));
        return;
      }
      if (this.configInfo.feeCoinId == this.configInfo.payCoinId) {
        let fee = this.amount * parseFloat(this.configInfo.feeRate);
        fee > parseFloat(this.configInfo.feeMin) ? this.free = this.common.cutXiaoNum1(fee) : this.free = parseFloat(this.configInfo.feeMin);
        if (!this.configInfo.hasFee) {
          this.free = 0;
        }
        let rate = parseFloat(this.configInfo.payCoinPrice/this.configInfo.returnCoinPrice), num = parseFloat(this.amount - this.free);
        this.conAmonut = this.common.cutXiaoNum1(num * rate);
      } else {
        let fee = this.returnAmount * parseFloat(this.configInfo.feeRate);
        fee > parseFloat(this.configInfo.feeMin) ? this.free = this.common.cutXiaoNum1(fee) : this.free = parseFloat(this.configInfo.feeMin);
        if (!this.configInfo.hasFee) {
          this.free = 0;
        }
        let num1 = this.returnAmount - this.free;
        this.conAmonut = this.common.cutXiaoNum1(num1);
      }
      this.loading = false;
      this.showTips = true;
    },

    onChange() {
      if (this.amount != "") {

        this.amount = this.common.cutXiaoNum1(this.amount)

        if (this.amount < parseFloat(this.configInfo.payCoinMin)) {
          this.$message.error(
            this.$t("quotes.desc21", { n1: this.configInfo.payCoinMin })
          );
        } else if (
          this.amount > parseFloat(this.configInfo.payCoinMax) &&
          parseFloat(this.oneCoinInfo.balance) >
          parseFloat(this.configInfo.payCoinMax)
        ) {
          this.$message.error(
            this.$t("quotes.desc22", { n1: this.configInfo.payCoinMax })
          );
        } else if (
          this.amount > parseFloat(this.configInfo.payCoinMax) &&
          parseFloat(this.oneCoinInfo.balance) <
          parseFloat(this.configInfo.payCoinMax)
        ) {
          this.amount = this.oneCoinInfo.balance;
        } else if (this.amount > parseFloat(this.oneCoinInfo.balance)) {
          this.amount = this.oneCoinInfo.balance;
        }
        let rate = parseFloat(this.configInfo.payCoinPrice/this.configInfo.returnCoinPrice);
        this.returnAmount = this.common.cutXiaoNum1(this.amount * rate);

      } else {
        this.returnAmount = '';
      }

    },
    //输入值改变
    onChange1() {
      if (this.returnAmount != "") {
        this.returnAmount = this.common.cutXiaoNum1(this.returnAmount)
        let rate = 1 / parseFloat(this.configInfo.payCoinPrice/this.configInfo.returnCoinPrice);
        this.amount = this.common.cutXiaoNum1(this.returnAmount * rate);
        if (this.amount < parseFloat(this.configInfo.payCoinMin)) {
          this.$message.error(
            this.$t("quotes.desc21", { n1: this.configInfo.payCoinMin })
          );
        } else if (
          this.amount > parseFloat(this.configInfo.payCoinMax) &&
          parseFloat(this.oneCoinInfo.balance) >
          parseFloat(this.configInfo.payCoinMax)
        ) {
          this.$message.error(
            this.$t("quotes.desc22", { n1: this.configInfo.payCoinMax })
          );
        } else if (
          this.amount > parseFloat(this.configInfo.payCoinMax) &&
          parseFloat(this.oneCoinInfo.balance) <
          parseFloat(this.configInfo.payCoinMax)
        ) {
          this.amount = this.oneCoinInfo.balance;
        } else if (this.amount > parseFloat(this.oneCoinInfo.balance)) {
          this.amount = this.oneCoinInfo.balance;
        }
      }
    },
    
    //选择币种
    selectInfo(data){
      this.amount = '';
      this.returnAmount = '';
      this.configInfo = data;
      this.getMoney(1, data.payCoinId);
      this.getMoney(2, data.returnCoinId);
    },
    //切换币对
    handlerSwitch() {
      if (this.configInfo.isTwoWay == 2) {
        return;
      }
      this.payCoinId = this.configInfo.returnCoinId;
      this.returnCoinId = this.configInfo.payCoinId;
      this.amount = '';
      this.returnAmount = '';
      this.$post(this.URL.quotes.configInfo, {
        configId: this.coinInfo.configId,
        payCoinId: this.payCoinId,
        returnCoinId: this.returnCoinId
      }).then((res) => {
        if (res.code == 0) {
          res.data.wisdomRate = this.common.cutXiaoNum1(res.data.wisdomRate)
          this.configInfo = res.data;
          this.getMoney(1, this.configInfo.payCoinId);
          this.getMoney(2, this.configInfo.returnCoinId);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //获取币种下拉框列表
    getConfigList() {
      this.$post(this.URL.quotes.configList, {
        hasQuota: true,
      }).then((res) => {
        if (res.code == 0) {
          this.paycoinList = res.data;
          if (this.paycoinList.length > 1) {
            this.selectInfo(this.paycoinList[0])
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // getConfig() {
    //   this.$post(this.URL.quotes.configInfo, {
    //     configId: this.coinInfo.configId,
    //     payCoinId: this.payCoinId == 0 ? this.coinInfo.payCoinId : this.payCoinId,
    //     returnCoinId: this.returnCoinId == 0 ? this.coinInfo.returnCoinId : this.returnCoinId
    //   }).then((res) => {
    //     if (res.code == 0) {
    //       res.data.wisdomRate = this.common.cutXiaoNum1(res.data.wisdomRate)
    //       this.configInfo = res.data;
    //     } else {
    //       this.$message.error(res.message);
    //     }
    //   });
    // },

    //获取余额
    getMoney(i, coinId) {
      this.$post(this.URL.assets.view, {
        relationCoinId: coinId,
        accountType: 3,
      }).then((res) => {
        if (res.code == 0) {
          res.data.balance = this.common.cutXiaoNum1(res.data.balance)
          if (i == 1) {
            this.oneCoinInfo = res.data;
          } else {
            this.twoCoinInfo = res.data;
          }
        }
      });
    },
    getline(coinId) {
      let _this = this;
      this.$post(this.URL.quotes.echarts, {
        coinId: coinId,
        page: 1,
        pageSize: 500
      }).then((res) => {
        if (res.code == 0) {
          this.drawData = res.data.list;
          this.drawData = res.data.list.reverse();
          let dataX = [],
            dataY = [];
          this.drawData.forEach((e) => {
            dataX.push(parseFloat(e.transactionPrice));
            let time = this.common.dateBJtoLocal(e.createTime), time1 = time.getTime(), time2 = 4 * 60 * 60 * 1000;
            let datetime = this.common.formatDate4(time1 + time2);
            e.datetime1 = this.common.formatDate(time1 + time2);
            e.datetime = datetime;
            dataY.push(datetime);
          });

          // setTimeout(() => {
          //   _this.drawLine(dataX, dataY);
          // }, 500);

        } else {
          this.$message.error(res.message);
        }
      });
    },
    onLoad() {
      this.showNull = false;
      this.$post(this.URL.quotes.record, {
        page: this.page,
        pageSize: 10,
      }).then((res) => {
        if (res.code == 0) {
          this.list = res.data.list;
          this.list.forEach(e => {
            let time = this.common.dateBJtoLocal(e.date), time1 = time.getTime(), time2 = 4 * 60 * 60 * 1000;
            e.datetime = this.common.formatDate(time1 + time2);
          })
          this.finished = true;
          this.totals = parseFloat(res.data.page.count);
          if (this.totals == 0) {
            this.showNull = true;
          }
        }
      });
    },

    drawLine(dataX, dataY) {
      // 基于准备好的dom，初始化echarts实例
      let maxLenth = 8, _this = this;
      if (window.screen.width < 768) {
        maxLenth = 4
      }
      const myChart = this.$echarts.init(document.getElementById("myChart"));
      myChart.setOption({
        tooltip: {
          showSymbol: true,
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
              formatter: function (params) {

                let inde = _this.drawData.find(item => item.datetime == params.value);
                if (params.axisDimension == 'y') {
                  params.value = parseFloat(params.value).toFixed(2)
                } else {
                  params.value = inde.datetime1
                }
                return params.value;
              }
            },

          },
          className: 'custom-tooltip-box',
          formatter: function (param) {
            let info = param[0];
            let inde = _this.drawData.find(item => item.datetime == info.name);
            let htmlText = '<div class="custom-tooltip-box"> <div style="padding:16px;">' + inde.datetime1 + "<br>" + "<span style='color:rgba(255, 255, 255, 0.40)'>" + `${_this.$t('quotes.desc25')}：` + "</span>" + '1 RC = ' + info.value + ' DU' + "<br>"
            '</div></div>';
            return htmlText;
          }
        },
        dataZoom: [
          {
            show: false,
            startValue: dataX.length - maxLenth // 数据展示的开始位置，从结束结束位置项前展示三条
          },
          {
            zoomOnMouseWheel: false, // 控制可以左右滑动
            type: 'inside' // 隐藏底部默认的滑动条
          }
        ],
        grid: {
          left: "5%",
          right: "8%",
          bottom: "0%",
          top: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: dataY,
          axisLabel: {
            //修改坐标系字体颜色
            show: true,
            interval: 0,
            textStyle: {
              color: "rgba(255, 255, 255, 0.6)",
            },
            lineStyle: {},
          },
        },
        yAxis: {
          type: "value",
          boundaryGap: true,

          axisLabel: {
            //修改坐标系字体颜色
            show: true,
            textStyle: {
              color: "rgba(255, 255, 255, 0.6)",
            },
            formatter: function (value, index) {
              return value.toFixed(2)
            }
          },
          splitLine: {
            //网格线
            show: false, //关闭网格线
          },
          axisTick: {
            //是否展示刻度线
            show: true,
            inside: false, //刻度线朝内还是朝外
          },
        },
        series: [
          {
            // name: `${_this.$t('quotes.desc25')}:`,
            data: dataX,
            type: "line",
            lineStyle: {
              width: 1, //外边线宽度
              color: "#C9FA5B", //外边线颜色
            },
            itemStyle: {
              color: '#C9FA5B'
            },
            showSymbol: false,
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(201, 250, 91, 1)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(201, 250, 91, 0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="less" scoped>
:deep(.custom-tooltip-box) {
  padding: 0 !important;
  border: none !important;
  border-radius: 6px !important;
  background: #5D5D5D;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
  color: #fff;
}

.quotes {
  width: 100%;
  padding: 136px 0 80px;
  background: url("../../public/images/new/landbg.png") center no-repeat;
  background-size: 100% 100%;

  .quotes-con {
    width: 1240px;
    margin: 0 auto;

    .quotes-title {
      position: relative;
      font-size: 24px;
      color: #ffffff;
      margin-bottom: 40px;

      p {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 14px;
        color: #c9fa5c;
        display: none;

        img {
          width: 5px;
          height: 8px;
          margin: 4px 0 0 4px;
        }
      }
    }

    .quotes-wrappar {
      justify-content: space-between;

      .slider-left {
        flex: 0 0 596px;
        padding: 24px 0;
        border-radius: 16px;
        background: #181818;

        .left-top {
          padding: 8px 32px;

          div {
            flex: 0 0 25%;
            font-size: 13px;
            color: rgba(255, 255, 255, 0.8);

            &:last-child {
              text-align: right;
            }

            &:nth-child(3) {
              text-align: center;
            }
          }
        }

        .left-list {
          padding: 0 32px;
        }

        .left-info {
          padding: 16px 0;

          .info-icon {
            flex: 0 0 25%;
            font-size: 16px;
            color: #ffffff;
            line-height: 37px;

            &:last-child {
              text-align: right;
            }

            &:nth-child(3) {
              text-align: center;
            }

            img {
              flex: 0 0 36px;
              width: 36px;
              height: 36px;
              margin-right: 8px;
              border-radius: 50%;
              object-fit: cover;
            }

            p {
              margin: 0 0 0 auto;
              width: 82px;
              height: 37px;
              background: url("../../public/images/new/btnbg6.png") center no-repeat;
              background-size: 100% 100%;
              cursor: pointer;
              font-size: 12px;
              color: #000000;
            }
          }

          .color1 {
            color: #c9fa5b;
          }

          .color2 {
            color: #eb4343;
          }
        }
      }

      .slider-right {
        flex: 0 0 624px;
        padding: 32px 0 40px;
        border-radius: 16px;
        background: #181818;

        .right-line {
          position: relative;
          height: 235px;

          .line-bg {
            position: absolute;
            top: 0;
            right: 44px;
            width: 523px;
            height: 214px;
            background: #101010;
            z-index: 0;
            border-left: 1px solid rgba(255, 255, 255, 0.4);
            box-sizing: border-box;
            display: none;
          }

          #myChart {
            position: absolute;
            top: 0;
            left: 0;
            height: 235px;
            width: 592px;
            z-index: 5;
          }
        }

        .right-con {
          padding: 32px 32px 0;

          .right-name {
            font-size: 20px;
            color: #ffffff;
          }

          .right-money {
            margin-top: 16px;
            font-size: 16px;
            color: #ffffff;
            line-height: 24px;

            img {
              width: 14px;
              height: 14px;
              margin: 5px 0 0 8px;
              border-radius: 50%;
              object-fit: cover;
            }

            ::v-deep .van-circle {
              margin: 3px 0 0 5px;
            }
          }

          .right-exchange {
            margin: 20px 0 12px;
            justify-content: space-between;

            .right-select {
              flex: 0 0 250px;
              width: 250px;

              &:last-child {
                .select-price {
                  text-align: right;
                }
              }

              .select-info {
                padding: 8px 0;
                border: 1px solid rgba(255, 255, 255, 0.2);
              }

              input {
                width: 127px;
                margin: 0 0 0 12px;
                height: 36px;
                font-size: 14px;
                color: #fff;

                &::placeholder {
                  color: rgba(255, 255, 255, 0.7);
                }
              }

              ::v-deep .el-dropdown {
                flex: 1;
                margin-right: 12px;
                height: 36px;
                background: rgba(255, 255, 255, 0.1);

                .el-dropdown-link {
                  position: relative;
                  display: flex;
                  width: 100%;
                  font-size: 14px;
                  color: #ffffff;
                  line-height: 36px;

                  .img1 {
                    width: 24px;
                    height: 24px;
                    margin: 6px 6px 0 8px;
                  }

                  .img2 {
                    position: absolute;
                    top: 50%;
                    right: 8px;
                    width: 10px;
                    height: 6px;
                    transform: translateY(-50%);
                  }
                }
              }

              .select-price {
                padding: 12px 0;
                font-size: 13px;
                color: #ffffff;
              }
            }

            .icon {
              flex: 0 0 44px;
              width: 44px;
              height: 44px;
              margin-top: 4px;
              cursor: pointer;
            }

            .grey {
              filter: grayscale(1);
            }
          }

          .right-btn {
            margin-top: 24px;
            width: 100%;
            height: 59px;
            background: url("../../public/images/new/btnsellbg.png") center no-repeat;
            background-size: 100% 100%;
            text-align: center;
            line-height: 59px;
            font-size: 20px;
            color: #fff;
            cursor: pointer;
          }

          .right-btn1 {
            margin-top: 24px;
            width: 100%;
            height: 59px;
            background: url("../../public/images/new/btnbg7.png") center no-repeat;
            background-size: 100% 100%;
            text-align: center;
            line-height: 59px;
            font-size: 20px;
            color: #000;
            cursor: pointer;
          }
        }
      }
    }

    .quotes-record {
      margin-top: 40px;

      .record-name {
        margin-bottom: 24px;
        font-size: 16px;
        color: #ffffff;
      }

      .record-tbody {
        padding: 20px 24px;
        border-radius: 8px;
        background: #181818;

        div {
          flex: 0 0 160px;
          font-size: 13px;
          color: rgba(255, 255, 255, 0.8);

          &:first-child {
            flex: 0 0 175px;
          }

          &:last-child {
            flex: 1;
            text-align: right;
          }
        }
      }

      .record-list {
        ::v-deep .van-list {
          .van-cell {
            background: none;
            padding: 26px 24px;

            &::after {
              border-bottom: 1px solid #1a1a1a;
            }

            .list-con {
              display: flex;
              flex-wrap: wrap;
            }

            .list-info {
              flex: 0 0 160px;
              font-size: 13px;
              color: #fff;
              line-height: 13px;

              &:first-child {
                flex: 0 0 175px;
              }

              // &:nth-child(4) {
              //   p {
              //     color: #eb4343;
              //   }
              // }

              &:last-child {
                flex: 1;
                justify-content: flex-end;
              }

              div {
                display: none;
                color: rgba(255, 255, 255, 0.8);
              }

              p {
                img {
                  width: 12px;
                  height: 12px;
                  margin-left: 4px;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}

.select-icon {
  width: 100%;
  font-size: 14px;
  color: #ffffff;
  line-height: 36px;

  img {
    width: 24px;
    height: 24px;
    margin: 6px 6px 0 0;
  }
}

.modal-con {
  position: relative;
  padding: 40px;
  width: 480px;

  .close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .modal-title {
    margin-bottom: 24px;
    font-size: 20px;
    color: #ffffff;
  }

  .modal-list {
    padding: 20px;
    border-radius: 8px;
    background: #242424;

    .list-info {
      margin-top: 20px;
      justify-content: space-between;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.6);

      &:first-child {
        margin-top: 0;

        // div {
        //   color: #eb4343;
        // }
      }

      p {
        color: #fff;
      }
    }
  }

  .modal-btn {
    margin-top: 20px;
    height: 50px;
    background: url("../../public/images/asset/btnbg2.png") center no-repeat;
    background-size: 100% 100%;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
  }

  .forgottitle {
    margin-top: 24px;
    color: #c9fa5b;
    font-size: 16px;
    cursor: pointer;
    text-align: right;
  }

  .modal-desc {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
  }

  .modal-input {
    margin: 20px 0 16px;
  }

  .modal-tips,
  .modal-tips1 {
    font-size: 14px;
    color: #eb4343;
  }

  .modal-tips1 {
    margin-top: 20px;
  }

  .modal-btncon {
    margin-top: 24px;
    height: 50px;
    background: url("../../public/images/new/btnbg16.png") center no-repeat;
    background-size: 100% 100%;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
  }
}

.h5header {
  display: none;
}

.planet-page {
  margin-top: 46px;
  padding: 0;

  ::v-deep .el-pagination {
    button {
      background-color: rgba(255, 255, 255, 0);
      color: #666666;
      font-size: 14px;
    }

    .el-pager {
      li {
        background: rgba(255, 255, 255, 0);
        font-size: 14px;
        color: #666666;
      }

      .is-active {
        background: #c9fa5b !important;
        border-radius: 4px !important;
        color: #000000 !important;
      }
    }
  }
}

.h5title {
  display: none;
}

.no-data {
  width: 100%;
  padding: 130px 0;

  img {
    display: block;
    width: 120px;
    height: 120px;
    margin: 0 auto 16px;
  }

  span {
    display: block;
    margin: 0 auto;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #a1a1a1;
  }
}

@media (max-width: 1200px) {
  .quotes {
    padding: 88px 16px 70px;

    .quotes-con {
      width: 100%;

      .quotes-title {
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 16px;

        p {
          display: flex;
        }
      }

      .quotes-wrappar {
        justify-content: flex-start;
        flex-wrap: wrap;

        .slider-left {
          margin-bottom: 20px;
          flex: 0 0 100%;
          border-radius: 8px;
        }

        .slider-right {
          flex: 0 0 100%;
          border-radius: 8px;

          .right-line {
            #myChart {
              width: 100%;
            }

            .line-bg {
              width: 93%;
              left: 5%;
            }
          }
        }
      }

      .quotes-record {
        display: none;
      }
    }
  }
}

@media (max-width: 767px) {
  .h5header {
    position: relative;
    // position: fixed;
    // top: 0;
    // left: 0;
    width: 100%;
    height: 44px;
    margin-bottom: 12px;
    background: #000;
    display: block;

    .return {
      position: absolute;
      top: 50%;
      left: 16px;
      width: 14px;
      height: 22px;
      transform: translateY(-50%);
      display: none;
    }

    .name {
      width: 80%;
      margin: 0 auto;
      font-size: 16px;
      color: #fff;
      height: 44px;
    }

    .lang {
      position: absolute;
      top: 50%;
      right: 16px;
      width: 20px;
      height: 20px;
      transform: translateY(-50%);

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .quotes {
    padding: 56px 16px 80px;

    .quotes-con {
      .quotes-title {
        display: none;
      }

      .quotes-wrappar {
        .slider-left {
          padding: 16px 16px 0;

          .left-top {
            padding: 0;
          }

          .left-list {
            padding: 0;

            .info-icon {
              font-size: 13px;
            }
          }
        }

        .slider-right {
          background: none;
          padding: 0;

          .right-line {
            padding: 12px;
            margin-bottom: 24px;
            min-height: 259px;
            background: #181818;
            border-radius: 8px;

            .line-bg {
              width: 86%;
              left: 12%;
            }

            #myChart {
              top: 12px;
            }
          }

          .right-con {
            padding: 0;

            .right-name {
              font-size: 18px;
            }

            .right-money {
              font-size: 14px;
              line-height: 18px;

              ::v-deep .van-circle {
                margin: 0 0 0 4px;
              }

              img {
                margin-top: 2px;
              }
            }

            .right-exchange {
              flex-wrap: wrap;

              .right-select {
                flex: 0 0 100%;

                .select-info {
                  input {
                    flex: 0 0 65%;
                  }
                }

                .select-price {
                  padding: 9px 0 0;
                  text-align: right;
                }
              }

              .icon {
                margin: 0 auto 16px;
                transform: rotate(90deg);
              }
            }

            .right-btn,
            .right-btn1 {
              font-size: 16px;
              height: 54px;
              line-height: 54px;
            }
          }
        }
      }
    }
  }

  .h5title {
    display: flex;
    padding: 20px 0;
    justify-content: space-between;

    .title-name {
      font-size: 16px;
      color: #ffffff;
      line-height: 16px;
    }

    .item-info-more {
      font-size: 14px;
      color: #a8a8a8;
      display: flex;
      line-height: 16px;

      img {
        width: 12px;
        height: 12px;
        margin: 3px 0 0 4px;
        transform: rotate(0deg);
        transition: 0.5s;
      }
    }

    .actimg {
      img {
        transform: rotate(180deg);
        transition: 0.5s;
      }
    }
  }

  .modal-con {
    width: 100% !important;
    padding: 32px 16px;

    .modal-title {
      padding-right: 40px;
    }

    .close {
      right: 16px;
    }

    .modal-btn {
      div {
        flex: 0 0 49%;
        height: 44px;
      }
    }
  }
}</style>
